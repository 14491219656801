import axios from 'axios';
import Cookies from 'js-cookie';
import { buildEndpoint, getSubdomain } from 'helpers/apiHelper';

const subdomain = getSubdomain(window.location.href);

const axiosInstance = axios.create({
    baseURL: buildEndpoint(),
    headers: { 'X-Subdomain': subdomain }
});

const getPlayerList = async (year = "current") => {
    return axiosInstance.get(`player/all/${year}`);
}

const getPlayer = async (playerId) => {
    return axiosInstance.get(`player/${playerId}`);
}

const editPlayer = async(id, player) => {
    
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.patch(
        `player/${id}`,
        player,
        { headers: headers }
    );
}

const deletePlayer = async(id) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.delete(
        `player/${id}`,
        { headers: headers }
    );
}

const addPlayer = async({firstName, lastName, positionName, value, pcId}) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.post(
        'player/add',
        {
            firstName: firstName, 
            lastName: lastName,
            positionName: positionName,
            value: value,
            pcId: pcId
        },
        { headers: headers }
    );
}

const uploadCSVFile = async({importCSV}) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    const formData = new FormData();
    formData.append('importCSV', importCSV);

    return axiosInstance.post(
        'player/upload-csv',
        formData,
        { headers: headers }
    );
}

const importMultiplePlayers = async(playersToImport) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.post(
        'player/add-multiple',
        {
            players: playersToImport
        },
        { headers: headers }
    );
}

const updateMultiplePlayers = async(playersToSave) => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.patch(
        'player/update-multiple',
        {
            players: playersToSave
        },
        { headers: headers }
    );
}

const editPlayerMatchData = async(playerId, gamePlayerId, matchData) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.patch(
        `player/match-data/${playerId}/${gamePlayerId}`,
        matchData,
        { headers: headers }
    );
}

const removePlayerFromMatch = async(playerId, gamePlayerId) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.delete(
        `player/match-data/${playerId}/${gamePlayerId}`,
        { headers: headers }
    );
}

const addPlayerToMatch = async ({matchId, playerId}) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.post(
        `match/${matchId}/add-player`,
        { playerId },
        { headers: headers }
    );
}

const getArchivePlayerStandingYears = async (playerId) => {
    return axiosInstance.get(`player/${playerId}/archive-tables`);
}

const getArchivePlayerGameweekData = async (playerId, year) => {
    return axiosInstance.get(`player/${playerId}/archive-data/${year}`);
}

const getPlayerPriceChanges = async (playerId) => {
    return axiosInstance.get(`price-changes/${playerId}`);
}

const updatePlayerPriceChanges = async (playerId, gameweek, priceChange) => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.post(
        `price-changes/${playerId}`,
        {
            priceChange: priceChange,
            gameweek: gameweek
        },
        { headers: headers }
    );
}

const getArchivePlayerSeasons = async () => {
    return axiosInstance.get('player/archive-tables');
}


export {
    getPlayerList,
    getPlayer,
    editPlayer,
    deletePlayer,
    addPlayer,
    importMultiplePlayers,
    updateMultiplePlayers,
    editPlayerMatchData,
    removePlayerFromMatch,
    addPlayerToMatch,
    getArchivePlayerSeasons,
    getArchivePlayerStandingYears,
    getArchivePlayerGameweekData,
    getPlayerPriceChanges,
    updatePlayerPriceChanges,
    uploadCSVFile
}