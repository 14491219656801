import React, { useState, useEffect } from 'react';

import { api } from 'api';
import { Playerlist } from 'components';

import useScreenSize from "hooks/useScreenSize";

const SLICE = 3;

const PlayerStandings = () => {

    const [{ isTabletOrMobile }] = useScreenSize();

    const [playerlistData, setPlayerlistData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getPlayerList = async () => {

        try {
            const results = await api.player.getPlayerList("current");

            const playerlistSlicedAndSorted = results.data.map(position => {
                const playerListSorted = position.players.sort((a, b) => b.totalPoints - a.totalPoints);
                const playerListSliced = playerListSorted.slice(0, SLICE);

                return { positionName: position.positionName, players: playerListSliced };
            });

            setPlayerlistData(playerlistSlicedAndSorted);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        getPlayerList();
    }, []);

    return <Playerlist
        isLoading={loading} 
        playerData={playerlistData} 
        size='small' 
        tabPosition={isTabletOrMobile ? "top" : "left"} 
        />
}

export default PlayerStandings;