import axios from 'axios';
import useSWR, { useSWRConfig } from "swr";

import { buildEndpoint, getSubdomain } from 'helpers/apiHelper';
import adminHelper from 'helpers/adminHelper';

const subdomain = getSubdomain(window.location.href);

const axiosInstance = axios.create({
    baseURL: buildEndpoint(),
    headers: { 'X-Subdomain': subdomain }
});

function useAdmin() {

    const { mutate } = useSWRConfig();

    const fetcher = async (endpoint) => {

        const adminResponse = await axiosInstance.get(endpoint);

        return adminResponse.data;
    }

    const { data, error, isLoading, mutate: mutateAdminRawData } = useSWR('admin', fetcher, { refreshInterval: 60000 }); // 1min

    const adminRefetch = () => {
        mutate('admin');
    }

    const mutateAdmin = (newData, revalidate = true) => {
        mutateAdminRawData(
            { ...data, ...newData },
            { revalidate: revalidate }
        );
    }

    return {
        adminData: new adminHelper(data),
        adminLoading: isLoading,
        adminError: error,
        adminRefetch,
        mutateAdmin,
    }
}

export default useAdmin;