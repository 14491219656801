import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { notification, Affix, Card, Typography, Space } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

import { Loading, SelectTeam as SelectTeamComponent } from 'components';

import { api as fccApi } from 'api';

import { CreateTeamContext } from '../../CreateTeam';
import { reducerActions } from '../reducer';
import { round } from 'helpers/mathsHelper';

import useScreenSize from "hooks/useScreenSize";
import useAdmin from "hooks/useAdmin";

import styles from '../styles';

const { Text } = Typography;

const SelectTeam = () => {

    const [{ isTabletOrMobile }] = useScreenSize();

    const [api, contextHolder] = notification.useNotification();

    const { state, dispatch } = useContext(CreateTeamContext);

    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(true);

    const { adminData } = useAdmin();

    const maxPlayers = adminData.getTeamSize();

    const loadPlayers = async () => {
        try {
            const results = await fccApi.player.getPlayerList();
            
            let playerData = [];

            results.data.map(position => {
                position.players.map((player, i) => {
                    playerData = [...playerData, player];
                });
            });

            dispatch({
                type: reducerActions.setPlayerData, 
                payload: playerData 
            });

            setLoading(false);

        } catch (error) {
            console.log(error);
        }
        
    };

    useEffect(() => {
        loadPlayers();
    }, []);

    useEffect(() => {
        onChange()
    }, [state.selectedPlayers]);

    const onChange = () => {

        const selectedRows = state.selectedPlayers;
        let teamBudget = round(adminData.teamBudget() + state.profitLoss, 1);

        // reset teamBudget if it's gone below the original amount
        if (teamBudget < adminData.teamBudget()) {
            teamBudget = adminData.teamBudget();
        }

        let errors = [];

        const notEnoughPlayersHasError = selectedRows.length < maxPlayers;
        const tooManyPlayersHasError = selectedRows.length > maxPlayers;

        if (tooManyPlayersHasError) {
            errors.push({
                'key': 'tooManyPlayers',
                'message': 'Too many players'
            });
        }
    
        // Do you got a player from each position, but not over the limit?
        let positionCount = [0,0,0,0,0];
        let value = 0;
    
        selectedRows.map(item => {
            switch(item.positionId) {
                case 1:
                    positionCount[1]++;
                    break;
                case 2:
                    positionCount[2]++;
                    break;
                case 3:
                    positionCount[3]++;
                    break;
                case 4:
                    positionCount[4]++;
                    break;
            }
    
            // either get value from playerList or nextPlayersList
            const playerIsNextPlayer = state.nextPlayers.find(player => player.id === item.id);
            const valueToAdd = playerIsNextPlayer ? playerIsNextPlayer.sellingPrice : item.adjustedValue;
            value = +(value + round(valueToAdd, 1)).toFixed(1);
        });

        const allPositionsHasError = selectedRows.length === maxPlayers &&
        (positionCount[1] === 0 || positionCount[2] === 0 || positionCount[3] === 0 || positionCount[4] === 0);

        if (allPositionsHasError) {
            errors.push({
                'key': 'allPositions',
                'message': 'You must select at least one player from each position'
            });
        }

        adminData.getPositions().map(position => {
            let key = 'tooMany' + position.positionName;

            const positionHasError = positionCount[position.positionId] > position.limit;

            if (positionHasError) {
                errors.push({
                    'key': key,
                    'message': `Too many ${position.positionName}s`
                });
            }
        });

        // Is your selection within budget?
        const tooExpensiveError = value > teamBudget;
    
        if (tooExpensiveError) {
            errors.push({
                'key': 'tooExpensive',
                'message': 'Your team is too expensive'
            });
        }

        setErrors(errors);
    
        dispatch({
            type: reducerActions.setCashRemain, 
            payload: round(teamBudget - value, 1)
        });
    
        dispatch({
            type: reducerActions.setHasErrors, 
            payload: errors.length > 0 || notEnoughPlayersHasError
        });
    };

    const calculateTransferPenalty = () => {
        if (
            ! state.forTransfers ||
            adminData.seasonHasNotStarted() ||
            state.wildcardGameweek === adminData.getGameweek() +1 ||
            state.transfersIncurringPenalties <= 0 
        ) {
            return '-0';
        }

        const transferDeduct = adminData.getTransferDeduct();
        return state.transfersIncurringPenalties * transferDeduct;
    }

    const MemoizedBank = React.memo(function Bank() {
        return (
            <Space direction="horizontal" size={4}>
                <Text>Bank:</Text>
                <Text 
                    strong 
                    type={state.cashRemain >= 0 ? 'success' : 'danger'}
                    >
                    {`${adminData.currency()}${state.cashRemain}m`}
                </Text>
            </Space>
        )
    });

    if (loading || !state.isReady) {
        return <Loading />;
    }

    return (
        <>
            {contextHolder}

            <Affix offsetTop={0} style={{ marginBottom: 24 }}>
                <Card 
                    className="affix--shadow"
                    title={<><Text><WarningOutlined /> Deadline: </Text><Text strong>{adminData.transferDeadline()}</Text></>}
                    headStyle={{ textAlign: 'center', background: '#f0f0f0' }}
                    size="small"
                    >
                    <Card.Grid style={styles.gridStyle(isTabletOrMobile)}>
                        <Space direction="horizontal" size={4}>
                            <Text>Free Transfers:</Text>
                            <Text strong>{
                                adminData.seasonHasNotStarted() || 
                                state.wildcardGameweek === adminData.getGameweek() +1 ||
                                ! state.forTransfers ? 
                                    'Unlimited' : 
                                    state.transfersAvailable
                                }
                            </Text>
                        </Space>
                    </Card.Grid>
                    
                    <Card.Grid style={styles.gridStyle(isTabletOrMobile)}>
                        <Space direction="horizontal" size={4}>
                            <Text>Cost:</Text>
                            <Text strong>{calculateTransferPenalty()} points</Text>
                        </Space>
                    </Card.Grid>

                    <Card.Grid style={styles.gridStyle(isTabletOrMobile)}>
                        <Space direction="horizontal" size={4}>
                            <MemoizedBank />
                        </Space>
                    </Card.Grid>

                    <Card.Grid style={styles.gridStyle(isTabletOrMobile)}>
                        <Space direction="horizontal" size={4}>
                            <Text>Selected:</Text>
                            <Text 
                                strong 
                                type={state.selectedPlayers.length !== maxPlayers ? 'danger' : 'success'}
                                >
                                {`${state.selectedPlayers.length}/${maxPlayers}`}
                            </Text>
                        </Space>
                    </Card.Grid>
                </Card>
            </Affix>

            <SelectTeamComponent 
                footer={<MemoizedBank />}
                errorMessages={errors}
                playerList={state.playerData} 
                handleChange={(selectedRows) => dispatch({
                    type: reducerActions.selectPlayer, 
                    payload: { selectedPlayers: selectedRows}
                })} 
                showSellingPrice={state.forTransfers}
                alreadySelectedPlayers={[...state.selectedPlayers]} />
        </>
    )
};

export default SelectTeam;