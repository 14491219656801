export const buildEndpoint = () => {
    return process.env.NODE_ENV === 'development' ? 
        `http://api.fcc.vm:8080/` :
        `https://plankton-app-aoh64.ondigitalocean.app/`
} 

export function getSubdomain(url) {
    let domain = url;
    if (url.includes("://")) {
        domain = url.split('://')[1];
    }
    const subdomain = domain.split('.')[0];
    return subdomain;
};