import axios from 'axios';
import Cookies from 'js-cookie';
import { buildEndpoint, getSubdomain } from 'helpers/apiHelper';

const subdomain = getSubdomain(window.location.href);

const axiosInstance = axios.create({
    baseURL: buildEndpoint(),
    headers: { 'X-Subdomain': subdomain }
});

const getPaymentToken = async () => {
    
    return axiosInstance.get('payment/token/generate');
}

const generateTransaction = async (nonce) => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.post(
        'payment', 
        { nonce },
        { headers: headers }
    );
}

export {
    getPaymentToken,
    generateTransaction
}