import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, Radio } from 'antd';

import { Playerlist, PageTitle } from 'components';
import { useChangePageTitle } from 'utils/useChangePageTitle';

import { api } from 'api';

const { Group, Button } = Radio;
const { Title } = Typography;

const PlayerStandings = () => {

    const [playerlistData, setPlayerlistData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [archivePlayerStandingYears, setArchivePlayerStandingYears] = useState([]);
    const [year, setYear] = useState("current");

    const changeYear = (e) => {
        setYear(e.target.value);
    }

    const getPlayerList = async (year) => {

        setLoading(true);

        try {
            const results = await api.player.getPlayerList(year);
            setPlayerlistData(results.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const getArchivePlayerStandingYears = async () => {
        try {
            const results = await api.player.getArchivePlayerSeasons();
            setArchivePlayerStandingYears(results.data);
        } catch (error) {
            console.log(error);
        }
    }

    const changePageTitle = useChangePageTitle();

    useEffect(() => {
        getArchivePlayerStandingYears();
        getPlayerList(year);
        changePageTitle('Player Standings');
    }, [year]);

    function ExtraTitle() {
        return (
            <>
                {year !== "current" && <Title level={5} strong>Year: {year}</Title>}
            </>
        )
    }

    return (
        <>
            <PageTitle title="Player Standings" extra={[<ExtraTitle />]} />
            <Playerlist isLoading={loading} playerData={playerlistData} />

            {archivePlayerStandingYears.length > 0 && (
                <Row justify="end">
                    <Col>
                        <Typography.Text>Previous Years: </Typography.Text>
                        <Group 
                            size="small" 
                            onChange={changeYear} 
                            defaultValue="current"
                            style={{ marginTop: 24 }}
                            >
                            <Button value="current">Current</Button>
                            {archivePlayerStandingYears.map(year =>
                                <Button key={year} value={year}>{year}</Button>
                            )}
                        </Group>
                    </Col>
                </Row>
            )}
        </>
    )
}

export default PlayerStandings;