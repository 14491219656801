import axios from 'axios';
import Cookies from 'js-cookie';
import { buildEndpoint, getSubdomain } from 'helpers/apiHelper';

const subdomain = getSubdomain(window.location.href);

const axiosInstance = axios.create({
    baseURL: buildEndpoint(),
    headers: { 'X-Subdomain': subdomain }
});

const changeCaptain = async (teamId, newCaptainId) => {
    
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    return axiosInstance.patch(
        `team/${teamId}/change-captain`, 
        { captain: newCaptainId },
        {
            headers: { Authorization: `Bearer ${token}` }
        });
}

const getTransferStatus = async (teamId) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.get(`team/${teamId}/transfer-status`, { headers: headers });
}

const setWildcardGameweek = async (teamId, wildcardGameweek) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.post(
        `team/${teamId}/activate-wildcard`, 
        { gameweek: wildcardGameweek },
        { headers: headers }
    );
}

const getTeamData = async (teamId, gameweek) => {

    const token = Cookies.get('__kopek');
    let headers = {};

    if (token) {
        headers = { Authorization: `Bearer ${token}` };
    }

    return axiosInstance.get(
        `team/${teamId}`, 
        { params: { gameweek: gameweek }, headers: headers }
    );
}

const activateTripleCaptain = async (teamId, gameweek) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.post(
        `team/${teamId}/activate-triple-captain`, 
        { gameweek: gameweek },
        { headers: headers }
    );
}

const makeTransfers = async (teamId, nextPlayerIds) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.post(
        `team/${teamId}/transfers`, 
        { 
            nextPlayers: nextPlayerIds
        },
        { headers: headers }
    );
}

const getTeamList = async (year) => {
    return axiosInstance.get(`team/all/${year}`);
}

const deleteTeam = async (userId) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.delete(
        `team/delete-by-user-id/${userId}`,
        { headers: headers }
    );
}

const createTeam = async (teamPlayers, teamCaptain, teamName) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.post(
        'team/create',
        {
            selectedPlayers: teamPlayers,
            captain: teamCaptain,
            teamName: teamName
        },
        { headers: headers }
    );
}

const changeTeamName = async (teamId, teamName) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.post(
        `team/${teamId}/change-team-name`,
        {
            teamName: teamName
        },
        { headers: headers }
    );
}

const getArchiveTeamStandingYears = async () => {
    return axiosInstance.get('team/archive-tables');
}

const getNetTransfers = async (teamId) => {
    return axiosInstance.get(`team/${teamId}/net-transfers`);
}

export { 
    changeCaptain, 
    getTransferStatus, 
    setWildcardGameweek,
    getTeamData,
    activateTripleCaptain,
    makeTransfers,
    getTeamList,
    deleteTeam,
    createTeam,
    getArchiveTeamStandingYears,
    changeTeamName,
    getNetTransfers
};
