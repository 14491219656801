import axios from 'axios';
import Cookies from 'js-cookie';
import { buildEndpoint, getSubdomain } from 'helpers/apiHelper';

const subdomain = getSubdomain(window.location.href);

const axiosInstance = axios.create({
    baseURL: buildEndpoint(),
    headers: { 'X-Subdomain': subdomain }
});

const getHomepageStats = async () => {
    return axiosInstance.get('stats');
}

const getAdmin = async () => {
    return axiosInstance.get('admin');
}

const getAdminForAdminPage = async () => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.get('admin/for-admin', { headers: headers });
}

const updateAdmin = async ({
    introText, 
    primaryColor, 
    seasonStart, 
    transferWindow, 
    canEdit, 
    gameweekAdvanceDay, 
    gameweekAdvanceTime,
    autoPriceChange,
    transferDeduct
}) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.patch(
        'admin/update',
        {
            introText: introText, 
            primaryColor: primaryColor,
            seasonStart: seasonStart,
            transferWindow: transferWindow,
            canEdit: canEdit,
            gameweekAdvanceDay: gameweekAdvanceDay,
            gameweekAdvanceTime: gameweekAdvanceTime,
            autoPriceChange: autoPriceChange,
            transferDeduct: transferDeduct
        },
        { headers: headers }
    );
}

const getImportedMatches = async () => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.get('match/imported-matches', { headers: headers });
}

const updateMatch = async (matchId, importMatch) => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.patch(
        `match/${matchId}`,
        {
            import: importMatch
        },
        { headers: headers }
    );
}

const importMatch = async (matchId, gameweek) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.post(
        'match/import', 
        {
            gameweek: gameweek,
            matchId: matchId
        },
        { headers: headers }
    );
}

const deleteMatch = async (matchId) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.delete(
        `match/${matchId}`, 
        { headers: headers }
    );
}

const reImportMatch = async (matchPcId) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.get(
        `match/reimport/${matchPcId}`, 
        { headers: headers }
    );
}

const getUsers = async () => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.get('admin/users', { headers: headers });
}

const updateUser = async (userId, userInfo) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.patch(
        `admin/update-user/${userId}`,
        userInfo,
        { headers: headers }
    );
}

const addPointsDeduction = async (postData) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.post(
        'deduction/add',
        postData,
        { headers: headers }
    );
}

const deletePointsDeduction = async (deductionId) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.delete(
        `deduction/${deductionId}`,
        { headers: headers }
    );
}

const getPlayersToImport = ({forceRefetch}) => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.get(
        'match/import-players', 
        { headers: headers, params: { forceRefetch } }
    );
}

const getMatchesWithoutPlayer = async (playerId) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.get(`match/without-player/${playerId}`, { headers: headers });
}

const uploadLogo = async ({logo}) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.post(
        'admin/upload-logo',
        { logo },
        { headers: headers }
    );
}

const deleteLogo = async () => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.delete(
        'admin/delete-logo',
        { headers: headers }
    );
}

const updatePointsStructure = async (pointsStructure) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.patch(
        'points/structure',
        { positions: pointsStructure },
        { headers: headers }
    );
}

const getEntryFundsSummary = async () => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.get(
        'admin/entry-payment-summary',
        { headers: headers }
    );
}

const addImportMatchRule = async ({weekday, matchType}) => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.post(
        'match/add-import-rule',
        { weekday, matchType },
        { headers: headers }
    );
}

const getMatchImportRules = async () => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.get(
        'match/import-rules',
        { headers: headers }
    );
}

const deleteMatchImportRule = async ({matchImportRuleId}) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.delete(
        `match/import-rule/${matchImportRuleId}`,
        { headers: headers }
    );
}

const getMatchData = async ({matchId}) => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.get(
        `match/${matchId}`,
        { headers: headers }
    );
}

const getLatestMatchesWithPlayers = async () => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.get(
        'match/latest-with-players',
        { headers: headers }
    );
}

const clearCache = async () => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.post(
        'admin/clear-cache',
        {},
        { headers: headers }
    );
}

const endSeason = async () => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.post(
        'admin/end-season',
        {},
        { headers: headers }
    );
}

const importManualMatch = async ({
    playerData, 
    homeTeam,
    awayTeam,
    matchDate,
    matchType,
    gameweek,
    matchId = null
}) => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.post(
        'match/import/manual',
        { 
            playerData,
            homeTeam,
            awayTeam,
            matchDate,
            matchType,
            gameweek,
            matchId
        },
        { headers: headers }
    );
}

const assignPlayerToMatchData = async (playerAndGamePlayerData) => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.put(
        'match/assign-players-to-data',
        { 
            updates: playerAndGamePlayerData
        },
        { headers: headers }
    );
}
export {
    getHomepageStats,
    getAdmin,
    updateAdmin,
    getAdminForAdminPage,
    getImportedMatches,
    updateMatch,
    importMatch,
    deleteMatch,
    reImportMatch,
    getUsers,
    updateUser,
    getPlayersToImport,
    getMatchesWithoutPlayer,
    uploadLogo,
    deleteLogo,
    addPointsDeduction,
    deletePointsDeduction,
    updatePointsStructure,
    getEntryFundsSummary,
    addImportMatchRule,
    getMatchImportRules,
    deleteMatchImportRule,
    getMatchData,
    clearCache,
    importManualMatch,
    assignPlayerToMatchData,
    endSeason,
    getLatestMatchesWithPlayers
}