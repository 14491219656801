import axios from 'axios';
import Cookies from 'js-cookie';
import { buildEndpoint, getSubdomain } from 'helpers/apiHelper';

const subdomain = getSubdomain(window.location.href);

const axiosInstance = axios.create({
    baseURL: buildEndpoint(),
    headers: { 'X-Subdomain': subdomain }
});

const getRules = async () => {
    return axiosInstance.get('points/structure');
}

const login = async (email, password) => {
    return axiosInstance.post('user/login', { email, password })
}

const forgotPassword = async (email) => {
    return axiosInstance.patch('user/forgot-password', { email })
}

const resetPassword = async (userId, newPassword, newPasswordHash) => {
    return axiosInstance.post('user/reset-password', { userId, newPassword, newPasswordHash })
}

const getUser = async (userId) => {
    
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.get(`user/${userId}`, { headers: headers });
}

const updateUserDetails = async (userId, values) => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.post(`user/${userId}`, values, { headers: headers });
}

const createAccount = async ({ email, name, password, ecbOptIn}) => {

    return axiosInstance.post(
        'user/register',
        {
            email, 
            name, 
            password, 
            ecbOptIn
        }
    );
}

const deleteAccount = async (userId) => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axiosInstance.delete(`user/${userId}`, { headers: headers });
}

export {
    getRules,
    login,
    forgotPassword,
    resetPassword,
    getUser,
    createAccount,
    updateUserDetails,
    deleteAccount
}